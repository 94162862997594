import moment from "moment";

export const formatDateToDDMMYYYY = (date: string) => {
  if (!date) return "";
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const formateDateToDDMMYYYYHHMM = (date: string) => {
  // note time is in 12 hour format with AM/PM
  if (!date) return "";
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const hoursStr = hours12.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hoursStr}:${minutesStr} ${ampm}`;
};

export const last7DaysDateRange = () => {
  const today = new Date();
  const last7Days = new Date(today.setDate(today.getDate() - 7));
  return [
    moment(last7Days).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const last30DaysDateRange = () => {
  const today = new Date();
  const last30Days = new Date(today.setDate(today.getDate() - 30));
  return [
    moment(last30Days).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const thisYearDateRange = () => {
  const today = new Date();
  const thisYear = new Date(today.getFullYear(), 0, 1);
  return [
    moment(thisYear).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const lastYearDateRange = () => {
  const today = new Date();
  const lastYear = new Date(today.getFullYear() - 1, 0, 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
  return [
    moment(lastYear).format("YYYY-MM-DD"),
    moment(lastYearEnd).format("YYYY-MM-DD"),
  ];
};

export const todayDateRange = () => {
  const today = new Date();
  return [
    moment(today).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ];
};

export const allTime = () => {
  return null;
};

export const getYearFromDate = (date: string) => {
  const dateObj = new Date(date);
  return dateObj.getFullYear();
};

export const formateDateToDDMMYYYYHHMMSS = (date: string) => {
  // note time is in 12 hour format with AM/PM
  if (!date) return "";
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const hoursStr = hours12.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hoursStr}:${minutesStr}:${secondsStr} ${ampm}`;
};

export const getFullFiscalYear = (startMonth: string, startDay: string) => {
  const today = moment();
  const currentYear = today.year();

  return moment([currentYear, Number(startMonth) - 1, Number(startDay)]);
};

export const getQuarterDateRange = (start: moment.MomentInput) => {
  const today = moment();
  let startOfFiscalYear = moment(start, "YYYY-MM-DD");

  if (today.isBefore(startOfFiscalYear)) {
    startOfFiscalYear = startOfFiscalYear.subtract(1, "year");
  }

  const quarters: any[] = [];
  for (let i = 0; i < 4; i++) {
    const quarterStart = startOfFiscalYear.clone().add(i * 3, "months");
    const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");
    quarters.push({
      start: quarterStart,
      end: quarterEnd,
    });
  }

  return function getQuarter(input: number | "this_quarter" | "last_quarter") {
    let quarterIndex: number = 0;

    // Map strings like "this_quarter" or "last_quarter" to quarter numbers
    if (typeof input === "string") {
      switch (input.toLowerCase()) {
        case "this_quarter":
          for (let i = 0; i < quarters.length; i++) {
            if (
              today.isBetween(quarters[i].start, quarters[i].end, "day", "[]")
            ) {
              quarterIndex = i + 1;
              break;
            }
          }
          break;
        case "last_quarter":
          for (let i = 0; i < quarters.length; i++) {
            if (
              today.isBetween(quarters[i].start, quarters[i].end, "day", "[]")
            ) {
              quarterIndex = i - 1;

              if (quarterIndex < 0) {
                // Handle wraparound to the last quarter of the previous year
                quarterIndex = 3; // Index for Q4 of the previous year
                const previousYearStart = startOfFiscalYear
                  .clone()
                  .subtract(1, "year");
                const lastQuarterStart = previousYearStart
                  .clone()
                  .add(3 * 3, "months"); // Start of Q4
                const lastQuarterEnd = lastQuarterStart
                  .clone()
                  .add(3, "months")
                  .subtract(1, "day");
                return {
                  start: lastQuarterStart.format("YYYY-MM-DD"),
                  end: lastQuarterEnd.format("YYYY-MM-DD"),
                };
              } else {
                quarterIndex = ((i - 1 + 4) % 4) + 1; // Wrap around to 4 if negative
              }
              break;
            }
          }
          break;
        default:
          throw new Error(
            "Invalid string input. Use 'this_quarter', 'last_quarter', or a number from 1 to 4.",
          );
      }
    } else if (typeof input === "number" && input >= 1 && input <= 4) {
      quarterIndex = input;
    } else {
      throw new Error(
        "Invalid input. Provide a number (1-4) or a valid string.",
      );
    }

    const selectedQuarter = quarters[quarterIndex - 1];

    return {
      start: selectedQuarter.start.format("YYYY-MM-DD"),
      end: selectedQuarter.end.format("YYYY-MM-DD"),
    };
  };
};
